import axios from "axios"
import { navigate } from "gatsby"
import React, { useEffect } from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  token: "",
  tokenVerified: false,
  loading: true,
  loadingProgress: null,
  user: {},
  alert: {
    active: false,
    content: "",
  },
}

function reducer(state, action) {
  switch (action.type) {
    case "TOKEN_VERIFIED":
      return {
        ...state,
        token: action.token,
        user: action.user,
        loading: false,
        tokenVerified: true,
      }
    case "TOKEN_DISCARDED":
      localStorage.removeItem("token")
      return {
        ...state,
        loading: false,
        tokenVerified: false,
        user: {},
        token: "",
      }
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      }
    case "LOADING":
      return {
        ...state,
        loading: action.value,
        loadingProgress: !action.value ? null : state.loadingProgress,
      }
    case "LOADING_PROGRESS": {
      return {
        ...state,
        loading: action.value,
        loadingProgress: action.progress,
      }
    }
    case "ALERT":
      return {
        ...state,
        alert: {
          active: true,
          content: action.content,
        },
      }
    case "CLOSE_ALERT":
      return {
        ...state,
        alert: {
          ...state.alert,
          active: false,
        },
      }
    case "LOGOUT":
      return {
        ...state,
        token: "",
        tokenVerified: false,
        loading: false,
        user: {},
      }
    default:
      return state
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const checkToken = () => {
    const localToken = localStorage.getItem("token")
    if (localToken) {
      axios
        .post(
          process.env.GATSBY_BACKEND_API_URL + "users/auth/verify-token",
          {},
          {
            headers: { Authorization: "Bearer " + localToken },
          }
        )
        .then(res => {
          dispatch({
            type: "TOKEN_VERIFIED",
            token: localToken,
            user: res.data.user,
          })
          if (window.location.pathname === "/") navigate("/home")
        })
        .catch(err => {
          console.log(err)
          dispatch({ type: "TOKEN_DISCARDED" })
          navigate("/")
        })
    } else {
      navigate("/")
      dispatch({ type: "LOADING", value: false })
    }
  }

  useEffect(() => {
    if (!state.tokenVerified) checkToken()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tokenVerified])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
