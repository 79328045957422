exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consults-js": () => import("./../../../src/pages/consults.js" /* webpackChunkName: "component---src-pages-consults-js" */),
  "component---src-pages-customer-portal-js": () => import("./../../../src/pages/customer-portal.js" /* webpackChunkName: "component---src-pages-customer-portal-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-customers-single-js": () => import("./../../../src/pages/customers/single.js" /* webpackChunkName: "component---src-pages-customers-single-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-js": () => import("./../../../src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-websites-js": () => import("./../../../src/pages/websites.js" /* webpackChunkName: "component---src-pages-websites-js" */)
}

